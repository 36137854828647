.custom-track-1 {
  border-color: #00cf53 !important;
}

.custom-skeleton-btn:hover,
.custom-skeleton-btn:active,
.custom-skeleton-btn:focus {
  color: white !important;
}
.custom-skeleton-btn:hover span:last-of-type,
.custom-skeleton-btn:active span:last-of-type,
.custom-skeleton-btn:focus span:last-of-type {
  background-color: #fff !important;
}

.hostel-card img {
  scale: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-duration: 150ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.hostel-card:hover .hostel-image {
  scale: 1.25;
}

.carousel-indicators > button {
  border-radius: 50%;
  max-width: 1rem;
  min-height: 1rem;
  background-color: white !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #00cf53 !important;
}
